import { cloneDeep, debounce } from 'lodash';
import { initialValues } from '~checkout/components/Cart/Purchaser/schema.js';
/**
 * Cart Store
 * ----------------------------
 *
 *
 */
export const useCartStore = defineStore('cart', () => {
    /**
     * Products
     * ------------------------------------------------------------------------------------------
     */
    const products = ref<Products>({});

    /**
     * Passengers
     * ------------------------------------------------------------------------------------------
     */
    const passengers = ref<Passenger[]>([]);

    /**
     * Offers
     * ------------------------------------------------------------------------------------------
     */
    const offers = ref<Offers>({});

    /**
     * Purchaser
     * ------------------------------------------------------------------------------------------
     */
    const purchaser = ref<Purchaser>(cloneDeep(initialValues));

    const hasPurchaser = computed(() => {
        return !Object.values(purchaser.value).every((value) => {
            if (value !== null && typeof value === 'object') {
                return Object.values(value).every(nestedValue => nestedValue === '');
            }
            return value === '' || value === undefined || value === null;
        });
    });

    /**
     * Optional Offers
     * ------------------------------------------------------------------------------------------
     */
    const optionalOffers = ref<OptionalOffer[]>([]);

    const selectedOptionalOfferRefs = ref<string[]>([]);
    const selectedOptionalOffers = computed(() => optionalOffers.value.filter(offer => selectedOptionalOfferRefs.value.includes(offer.offerRef)));

    /**
     * Other
     * ------------------------------------------------------------------------------------------
     */
    const lastFlow = ref<Flow>();

    /**
     * Quote
     * ------------------------------------------------------------------------------------------
     */
    const quote = ref<Quote>();
    const quoteIncludingOptionalOffers = ref<Quote>();
    const isReactiveQuoteLoading = ref(false);

    // load quote for all offers.
    // Load quote for all offers without optional offers to get them returned as possible options.
    // Then load quote for all offers including additionals to get the final price.
    const loadQuote = debounce(async () => {
        // load offers without optional offers
        ({ data: {
            quote: quote.value,
            optionalOffers: optionalOffers.value,
        } } = await apiLoadCartQuote(Object.values(offers.value), passengers.value, []));

        // load offers with optional offers
        ({ data: {
            quote: quoteIncludingOptionalOffers.value,
        } } = await apiLoadCartQuote(Object.values(offers.value), passengers.value, selectedOptionalOffers.value));

        return true;
    }, 100);

    // If any of the offers (or selected optionals) change, reload the quote, so we always have up-to-date prices
    watch([offers, selectedOptionalOfferRefs], () => {
        if (!isReactiveQuoteLoading.value) {
            return;
        }
        loadQuote();
    }, { deep: true });

    /**
     * ------------------------------------------------------------------------------------------
     */

    function reset() {
        products.value = {};
        passengers.value = [];
        offers.value = {};
        purchaser.value = cloneDeep(initialValues);
        optionalOffers.value = [];
        selectedOptionalOfferRefs.value = [];
        lastFlow.value = undefined;
        quote.value = undefined;
        isReactiveQuoteLoading.value = false;
    }

    return {
        // Products
        products,

        // Passengers
        passengers,

        // Offers
        offers,

        // Purchaser
        purchaser,
        hasPurchaser,

        // Optional Offers
        optionalOffers,
        selectedOptionalOfferRefs,
        selectedOptionalOffers,

        // Other
        lastFlow,

        // Quote
        quote,
        quoteIncludingOptionalOffers,
        isReactiveQuoteLoading,
        loadQuote,

        reset,
    };
}, {
    persist: {
        omit: [
            'isReactiveQuoteLoading',
        ],
        ...storePersist.storePersistOptions,
    },
});
